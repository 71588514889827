<template>
  <component is="b-card">
    <div>
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <!-- Field: Client -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Client')" label-for="client">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="clients"
                label="name"
                input-id="client"
                v-model="clientValue"
                :loading="loading"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <b-card no-body class="mb-0">
        <b-table
          id="scrolle-table"
          ref="refOrderListTable"
          class="position-relative"
          :items="fetchOrders"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('No matching records found')"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="consulteOrder(data.item)"
            >
              <feather-icon icon="EyeIcon" size="16" />
              <!--Consult-->
            </b-button>
            &nbsp;
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="btn-icon"
              :disabled="disabled"
              @click="ConfirmAllLigne(data.item, 'delivered')"
            >
              <feather-icon icon="CheckIcon" size="16" />
              <!--Delivred-->
            </b-button>
            &nbsp;
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="ConfirmAllLigne(data.item, 'not_delivered')"
            >
              <feather-icon icon="XSquareIcon" size="16" />
              <!--Not Delivred-->
            </b-button>
            &nbsp;
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="ShowMap(data.item)"
            >
              <feather-icon icon="MapIcon" size="16" />
              <!--Not Delivred-->
            </b-button>
            <b-modal
              :ref="'consulte-order-' + data.item.id"
              centered
              ok-only
              size="xl"
              @hidden="onConsulteOrderModalHidden"
              :title="$t('Consultation')"
              hide-footer
            >
              <div class="flex_box">
                <table style="width: max-content !important">
                  <tbody>
                    <tr>
                      <td class="color border padding_table">
                        <strong>{{ $t("Client") }}</strong>
                      </td>
                      <td class="border padding_table">{{ client }}</td>
                    </tr>
                    <tr class="border">
                      <td class="color border padding_table">
                        <strong>{{ $t("Order source") }}</strong>
                      </td>
                      <td class="border padding_table">{{ orderSource }}</td>
                    </tr>
                  </tbody>
                </table>
                <table style="width: max-content !important">
                  <tbody>
                    <tr>
                      <td class="color border padding_table">
                        <strong>{{ $t("Order date") }}</strong>
                      </td>
                      <td class="border padding_table">{{ orderDate }}</td>
                    </tr>
                    <tr class="border">
                      <td class="color border padding_table">
                        <strong>{{ $t("Order add by") }}</strong>
                      </td>
                      <td class="border padding_table">{{ operator }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <b-table
                id="scrolle-table"
                show-empty
                :empty-text="$t('No matching records found')"
                :items="orderLines"
                :fields="fields"
                responsive
              >
                <template #cell(state)="data">
                  {{ data.item.state.name }}
                </template>
              </b-table>
            </b-modal>
            <b-modal
              ref="show-map"
              centered
              ok-only
              size="lg"
              hide-footer
            >
            <template>
              <iframe :src="map" width="100%" height="450" frameborder="0" style="border:0" allowfullscreen class="embed-responsive-item"></iframe>
            </template>
            </b-modal>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{
                $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
              }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalOrders"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BAlert,
  BLink,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
  BImg,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import useOrderTrackingList from "./useOrderTrackingList";
import Ripple from "vue-ripple-directive";
import CustomOrderLineStateBadge from "@/views/ui/CustomOrderLineStateBadge.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    CustomOrderLineStateBadge,
    vSelect,
    flatPickr,
    BTab,
    BTabs,
    BAlert,
    BLink,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BImg,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BPagination,
    BDropdown,
    BDropdownItem,
    BModal,
    VBModal,
    BCardText,
    BBadge,
  },

  async mounted() {
    try {
      this.loading = true;
      const res = await Promise.all([
        instance.get("/parameters/clients/"),
      ]);
      this.clients = res[0].data;
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    } finally {
      this.loading = false;
    }
  },
  data() {
    return {
      loading:null,
      fields: [
        { key: "client_annexe", label: this.$t("client annexe") },
        { key: "product", label: this.$t("product") },
        { key: "available_qty", label: this.$t("available qty") },
        { key: "available_piece", label: this.$t("available piece") },
        { key: "shipping_type", label: this.$t("shipping type") },
        { key: "shipping_date", label: this.$t("shipping date") },
        { key: "emergency_degree", label: this.$t("emergency degree") },
      ],
      columns: [
        { key: "client_annexe", label: this.$t("client annexe") },
        { key: "actions", label: this.$t("actions") },
      ],
      orderLines: [],
      OrderlineAnnexes: [],
      orderDate: null,
      client: null,
      orderSource: null,
      shippingType: null,
      shippingDate: null,
      operator: null,
      clients: [],
      order: null,
      disabled:false,
      map:null,
    };
  },
  methods: {
    async ShowMap(order) {
      try {
        this.OrderlineAnnexes = [];
        const annexes = [];
        this.order = order;
        const res = await instance.get(`/orders/${order.id}/order-lines/`, {
          params: {
            state_id: "ready",
            role: "seller",
          },
        });
        res.data.forEach((orderLine) => {
          const {
            client_annexe,
          } = orderLine;

          if(client_annexe!=null){
            var annexe_latitude = client_annexe.latitude;
            var annexe_longitude = client_annexe.longitude;
            var annexe_id = client_annexe.id;
          }else{
            var annexe_latitude = null;
            var annexe_longitude = null;
          }
          
          annexes.push({
            annexe_id:annexe_id,
            annexe_latitude:annexe_latitude,
            annexe_longitude:annexe_longitude,
          });
        });
        this.OrderlineAnnexes = annexes.filter((annexes, index, self) => 
            index === self.findIndex((t) =>t.annexe_id === annexes.annexe_id)
        );
        this.$refs[`show-map`].show();
        this.map =`https://maps.google.com/maps?q=${this.OrderlineAnnexes[0].annexe_latitude},${this.OrderlineAnnexes[0].annexe_longitude}&z=16&output=embed`;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async ConfirmAllLigne(order,state) {
      const resutls = await this.$bvModal.msgBoxConfirm(
        this.$t("Are you sure you want to save?"),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (!resutls) {
        return;
      }
      this.disabled = true;
      try{
          await instance.post(`/orders/${order.id}/order-lines/`, {
          order_lines: [],
          state: state,
          role: "seller",
        });

        if(state === "delivered"){
          this.disabled = false;
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Success"),
              text: this.$t("The orders has been delivered"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }else{
          this.disabled = false;
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Success"),
              text: this.$t("The orders has been not delivered"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async consulteOrder(order) {
      this.orderDate = order.date;
      this.client = order.client.name;
      if (order.source != null) {
        this.orderSource = order.source.name;
      }
      if (order.operator != null) {
        this.operator = order.operator.name;
      }
      try {
        this.orderLines = [];
        this.order = order;

        const res = await instance.get(`/orders/${order.id}/order-lines/`, {
          params: {
            state_id: "ready",
            role: "seller",
          },
        });
        res.data.forEach((orderLine) => {
          const {
            product_shortcut
          } = orderLine.product_combination;
          const {
            state,
            ordered_qty,
            piece,
            available_qty,
            rest_qty,
            available_piece,
            shipping_type,
            shipping_date,
            emergency_degree,
            client_annexe,
          } = orderLine;
          if(emergency_degree!=null){
            var emergency_degree_name = emergency_degree.name;
          }else{
            var emergency_degree_name = null;
          }

          if(client_annexe!=null){
            var client_annexe_name = client_annexe.name;
          }else{
            var client_annexe_name = null;
          }

          this.orderLines.push({
            id: orderLine.id,
            product:product_shortcut,
            state: state,
            ordered_qty: ordered_qty,
            rest_qty:rest_qty,
            available_qty: available_qty,
            piece: piece,
            available_piece: available_piece,
            shipping_type: shipping_type.name,
            shipping_date: shipping_date,
            ref: order.ref,
            emergency_degree: emergency_degree_name,
            client_annexe:client_annexe_name,
          });
        });

        this.$refs[`consulte-order-${order.id}`].show();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async onConsulteOrderModalHidden() {
      this.orderLines = [];
    },
    fetchClientOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/clients/", {
            params: { search },
          });
          this.clientsRes = res.data.results;
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },

  setup(props) {
    const {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
      dockValue,
    } = useOrderTrackingList();

    return {
      // Sidebar
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
      dockValue,
    };
  },
};
</script>

<style>
.ButtonColor {
  box-shadow: 0 8px 25px -8px #7367f0 !important;
  border-color: none;
  background-color: #7367f0 !important;
}
.load {
  display: none;
}
#baniere_gauche {
  height: 100%;
  float: left;
  top: 0;
  left: 0;
}

#baniere_droite {
  position: relative;
  height: 100%;
  float: right;
  top: 0;
  right: 0;
}
.column-width {
    width: 93px;
}
td.color {
  background-color: #f3f2f7;
}
h5.state_color {
  background-color: #7367f0;
  color: white;
  padding: 0.3rem 0.5rem;
  text-align: center;
  display: inline-block;
  font-size: 85%;
  font-weight: 600;
  line-height: 1;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
    border 0s;
  border-radius: 0.358rem;
}
.Checkcolor {
  color: green;
}
.Cancelcolor {
  color: red;
}
table#scrolle-table {
  white-space: nowrap;
}
#gmap {height:300px;width:500px;}
</style>
